import { AnimationOnScroll } from "react-animation-on-scroll";
import RoundIcon from "./RoundIcon";
import classes from "./RoundIconDescription.module.css";
const RoundIconDescription = (props) => {
  return (
    <div
      className={
        props.link ? classes["div-servicios"] : classes["div-servicios-custom"]
      }
    >
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
        <RoundIcon link={props.link} content={props.content} />
        <h4 style={{ textAlign: "center" }}>{props.title}</h4>
        <p style={{ textAlign: "center", wordBreak: "break-word" }}>
          {props.description}
        </p>
      </AnimationOnScroll>
    </div>
  );
};
export default RoundIconDescription;
