import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import en from "./translations/en.json";
import es from "./translations/es.json";

const lang = window.navigator.language;

i18next.init({
  interpolation: { escapeValue: false },
  lng: lang,
  fallbackLng: "es",
  resources: {
    en: {
      common: en,
    },
    es: {
      common: es,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </BrowserRouter>
);
