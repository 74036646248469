import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileMedical,
  faHeartPulse,
  faLaptopCode,
} from "@fortawesome/free-solid-svg-icons";

const ESaludServices = [
  {
    title: "planAdopcionHCEN.title",
    desc: "planAdopcionHCEN.description",
    icon: <FontAwesomeIcon icon={faFileMedical} />,
  },
  {
    title: "seguridadESalud.title",
    desc: "seguridadESalud.description",
    icon: <FontAwesomeIcon icon={faHeartPulse} />,
  },
  {
    title: "solucionesTecnologicas.title",
    desc: "solucionesTecnologicas.description",
    icon: <FontAwesomeIcon icon={faLaptopCode} />,
  },
];
export default ESaludServices;
