
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloud,
  faMicrochip,
  faServer,
} from "@fortawesome/free-solid-svg-icons";

const InfraestructuraServices = [
  {
    title: "estrategiaServicios.title",
    desc: "estrategiaServicios.description",
    icon: <FontAwesomeIcon icon={faCloud} />,
  },
  {
    title: "softwareBase.title",
    desc: "softwareBase.description",
    icon: <FontAwesomeIcon icon={faMicrochip} />,
  },
  {
    title: "telecomunicaciones.title",
    desc: "telecomunicaciones.description",
    icon:  <FontAwesomeIcon icon={faServer} />,
  },
];
export default InfraestructuraServices;
