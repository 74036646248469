const IconButtonLink = (props) => {
  return (
    <div onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      <a href={props.href} target={props.target} rel="noreferrer">
        <button className={props.classButton}>{props.icon}</button>
      </a>
    </div>
  );
};

export default IconButtonLink;
