import classes from "./MainTitle.module.css";

const MainTitle = (props) => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(255,255,255,.7), rgba(255,255,255,.7)),url(${props.img})`,
        }}
        className={props.classMainTitle}
      >
        <div className={classes.content}>
          <div>
            <h1 className={classes["index-title"]}>{props.title}</h1>
            <p className={classes["index-text"]}>{props.description}</p>
          </div>
          <img className={props.classImg} src={props.indexImg} alt="" />
          {props.icon}
        </div>
      </div>
    </div>
  );
};
export default MainTitle;
