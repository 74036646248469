
import img_cicloDesarrollo from "../../assets/qoxit_ciclo de desarrollo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloud,
  faHardDrive,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";

const SecurityServices = [
  {
    title: "seguridadInformacion.title",
    desc: "seguridadInformacion.description",
    icon: <FontAwesomeIcon icon={faCloud} />,
  },
  {
    title: "seguridadInformatica.title",
    desc: "seguridadInformatica.description",
    icon: <FontAwesomeIcon icon={faUserShield} />,
  },
  {
    title: "seguridadAplicaciones.title",
    desc: "seguridadAplicaciones.description",
    icon: <FontAwesomeIcon icon={faHardDrive} />,
    img: img_cicloDesarrollo,
  },
];
export default SecurityServices;
