import classes from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import IconButtonLink from "../IconButtonLink";

const Footer = () => {
  return (
    <div className={classes.main}>
      <div className={classes.location}>
        <a
          href="https://www.google.com/maps/place/QoxIT/@-34.9109628,-56.1688766,17z/data=!3m1!4b1!4m6!3m5!1s0x959f81c8be17a725:0x398000e81d7c0cee!8m2!3d-34.9109672!4d-56.1663017!16s%2Fg%2F11s_h7jkd5?entry=ttu"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <div>
            <FontAwesomeIcon
              icon={faLocationDot}
              style={{
                fontSize: 20,
                color: "#fff",
              }}
            />
            <span className={classes["address"]}>
              Julio Herrera y Reissig 963
            </span>
          </div>
        </a>
        <p>+598 2418 7883</p>
        <p>Montevideo - Uruguay</p>
      </div>

      <div className={classes["contact-buttons"]}>
        <IconButtonLink
          classButton={classes.icons}
          target="_blank"
          href="mailto:contacto@qoxit.com"
          icon={<FontAwesomeIcon icon={faEnvelope} />}
        />
        <IconButtonLink
          classButton={classes.icons}
          target="_blank"
          href="https://www.linkedin.com/company/qoxit/"
          icon={<FontAwesomeIcon icon={faLinkedin} />}
        />
        <IconButtonLink
          classButton={classes.icons}
          target="_blank"
          href="https://www.instagram.com/qoxit_uy/"
          icon={<FontAwesomeIcon icon={faInstagram} />}
        />
      </div>
      <span className={classes["copyright"]}>
        Copyright © QoxIT {new Date().getFullYear()}
      </span>
    </div>
  );
};

export default Footer;
