import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import qoxitLogoBlanco from "../../assets/img_logo/logo_QoxIT_transparente.png";
import classes from "./Header.module.css";
import Sections from "../data/Sections";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faBars } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [state, setState] = useState({ clicked: false });
  const { i18n } = useTranslation();
  const { t } = useTranslation("common");

  const handleClick = () => {
    setState({ clicked: !state.clicked });
  };

  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  if (i18n.language === "en-US") {
    i18n.changeLanguage(window.navigator.language === "en" ? "en" : "es");
  }

  const getLanguageEmphasis = (lang) => {
    if (i18n.language.toLowerCase() === lang.toLowerCase()) {
      return { fontWeight: "bold", fontSize: "1.1rem" };
    }
    return {};
  };

  return (
    <>
      <nav className={classes.NavbarItems}>
        <NavLink to={"/"}>
          <img src={qoxitLogoBlanco} className={classes.photo} alt="Inicio" />
        </NavLink>
        <ul className={classes["nav-wrapper"]}>
          {Sections.map((item, index) => {
            return (
              <li key={index}>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? classes["nav-sections-active"]
                      : classes["nav-sections"]
                  }
                  to={item.url}
                >
                  {t(item.title)}
                </NavLink>
              </li>
            );
          })}
          <div style={{ marginLeft: "3rem" }}>
            <span
              style={getLanguageEmphasis("es")}
              className={classes["language-label"]}
              onClick={() => handleLanguage("es")}
              title="Español"
            >
              ES
            </span>
            <span className={classes["language-separator"]}>|</span>
            <span
              style={getLanguageEmphasis("en")}
              className={classes["language-label"]}
              onClick={() => handleLanguage("en")}
              title="English"
            >
              EN
            </span>
          </div>
        </ul>

        <div className={classes["menu-selector"]}>
          <div className={classes["menu-icons"]} onClick={handleClick}>
            {state.clicked ? (
              <FontAwesomeIcon icon={faX} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faBars} size="xl" />
            )}
          </div>
          <span
            className={classes["language-label"]}
            onClick={() => handleLanguage("es")}
            title="Español"
          >
            ES
          </span>
          <span className={classes["language-separator"]}>|</span>
          <span
            className={classes["language-label"]}
            onClick={() => handleLanguage("en")}
            title="English"
          >
            EN
          </span>
        </div>
      </nav>
      {state.clicked && (
        <ul className={classes["nav-wrapper-active"]}>
          {Sections.map((item, index) => {
            return (
              <li key={index}>
                <NavLink
                  className={classes["nav-sections"]}
                  to={item.url}
                  onClick={handleClick}
                >
                  {t(item.title)}
                </NavLink>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default Header;
