import imgDrogueria from "../../assets/img_clients/img_drogueria.jpg";
import imgCirculoCatolico from "../../assets/img_clients/img_circulo.jpg";
import imgAtgen from "../../assets/img_clients/img_atgen.jpg";
import imgMedicaUruguaya from "../../assets/img_clients/img_medica.png";
import imgLatechco from "../../assets/img_clients/img_latechco.jpg";
import imgBid from "../../assets/img_clients/img_bid.jpg";
import imgHeyNow from "../../assets/img_clients/img_hey-now.jpg";
import imgAguadaPark from "../../assets/img_clients/img_aguarda-park.jpg";
import imgClinicaInternacional from "../../assets/img_clients/img_clinica-internacional.png";
import imgFinancieraConfianza from "../../assets/img_clients/img_financiera-confianza.png";
import imgMinisterioAgricultura from "../../assets/img_clients/img_ministerio-agricultura.png";
import imgAgesic from "../../assets/img_clients/img_agesic.png";
import imgMinisterioSalud from "../../assets/img_clients/img_ministerio-salud.png";
import imgCanarias from "../../assets/img_clients/img_canarias.jpg";
export const SliderData = [
  {
    image: imgAgesic,
    class: "image-client image-client-agesic",
  },
  {
    image: imgAguadaPark,
    class: "image-client",
  },
  {
    image: imgAtgen,
    class: "image-client",
  },
  {
    image: imgBid,
    class: "image-client",
  },
  {
    image: imgCanarias,
    class: "image-client image-client-narrow-sm",
  },
  {
    image: imgCirculoCatolico,
    class: "image-client",
  },
  {
    image: imgClinicaInternacional,
    class: "image-client image-client-narrow-sm",
  },
  {
    image: imgDrogueria,
    class: "image-client",
  },
  {
    image: imgFinancieraConfianza,
    class: "image-client image-client-narrow-sm",
  },
  {
    image: imgHeyNow,
    class: "image-client image-client-hey-now",
  },
  {
    image: imgLatechco,
    class: "image-client",
  },
  {
    image: imgMedicaUruguaya,
    class: "image-client image-client-narrow",
  },
  {
    image: imgMinisterioAgricultura,
    class: "image-client",
  },
  {
    image: imgMinisterioSalud,
    class: "image-client",
  },
];
