import img2 from "../../assets/img_team/qoxit_equipo_img2.jpg";
import img3 from "../../assets/img_team/qoxit_equipo_img3.jpg";
import img4 from "../../assets/img_team/qoxit_equipo_img4.jpg";
import img8 from "../../assets/img_team/qoxit_equipo_img8.jpg";
import img7 from "../../assets/img_team/qoxit_equipo_img7.jpg";
import img1 from "../../assets/img_team/qoxit_equipo_img1.jpg";

export const SliderDataEquipo = [
  {
    image: img1,
    class: "image-equipo",
  },
  {
    image: img2,
    class: "image-equipo",
  },
  {
    image: img3,
    class: "image-equipo",
  },
  {
    image: img4,
    class: "image-equipo",
  },

  {
    image: img7,
    class: "image-equipo",
  },
  {
    image: img8,
    class: "image-equipo",
  },
];
