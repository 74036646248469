import classes from "./RoundIcon.module.css";
import { NavLink } from "react-router-dom";
const RoundIcon = (props) => {
  return (
    <>
      {props.link ? (
        <NavLink to={props.link}>
          <div className={classes["div-boton"]}>
            <button className={classes["boton-servicio"]}>
              {props.content}
            </button>
          </div>
        </NavLink>
      ) : (
        <div className={classes["div-boton-custom"]}>
          <div className={classes["boton-servicio"]}>{props.content}</div>
        </div>
      )}
    </>
  );
};
export default RoundIcon;
