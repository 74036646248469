export const Sections = [
  { title: "header.inicio", url: "/index" },
  { title: "header.consultoria", url: "/consultoria" },
  { title: "header.seguridad", url: "/seguridad" },
  { title: "header.desarrollo", url: "/desarrollo" },
  { title: "header.infraestructura", url: "/infraestructura" },
  { title: "header.eSalud", url: "/esalud" },
  { title: "header.equipo", url: "/equipo" },
  // { title: "header.contacto", url: "/contacto" },
];
export default Sections;
