import classes from "./ComponentDiv.module.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const ComponentDiv = (props) => {
  return (
    <div
      style={{
        backgroundImage: "url('../assets/img_bg/Qoxit_Institucional.jpg')",
      }}
      className={classes["div-p-img"]}
    >
      <AnimationOnScroll
        animateOnce={true}
        className={classes["div-p"]}
        animateIn="animate__fadeInUp"
      >
        <p>{props.text}</p>
      </AnimationOnScroll>
      {props.img && (
        <AnimationOnScroll animateOnce animateIn="animate__fadeInUp" delay={2}>
          <img
            className={classes["class-img"]}
            src={props.img}
            alt={props.alt}
          ></img>
        </AnimationOnScroll>
      )}
    </div>
  );
};

export default ComponentDiv;
