import React from "react";
import MainTitle from "../components/MainTitle";
import { useTranslation } from "react-i18next";
import DevelopmentServices from "../components/data/DesarrolloServicios";
import RoundIconDescription from "../components/RoundIconDescription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import imgDesarrollo from "../assets/img_bg/Qoxit_Desarrollo.jpg"
const Desarrollo = () => {
  const { t } = useTranslation("common");
  return (
    <div>
      <MainTitle
        title={t("desarrollo.title")}
        description={t("desarrollo.description")}
        classImg="image-service"
        classTitle="service-title"
        classParagraph="service-text"
        classMainTitle="div-main-title"
        img={imgDesarrollo}
        icon={
          <FontAwesomeIcon
          className="image-service"
          size="6x"
          color="#4C56A5"
          icon={faCode}
          style={{ filter: "drop-shadow(0 0 0.75rem)" }}
        />
        }
      />
      <div className="div-main">
      <div className="div-pages-servicios">
        {DevelopmentServices.map((item, index) => {
          return (
            <RoundIconDescription
              key={index}
              class="boton-servicio"
              divClass="div-servicios-custom"
              divBoton="div-boton"
              content={item.icon}
              title={t(`desarrollo.${item.title}`)}
              description={t(`desarrollo.${item.desc}`)}
            />
          );
        })}
        </div>
      </div>
    </div>
  );
};
export default Desarrollo;
