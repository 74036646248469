import React from "react";
import MainTitle from "../components/MainTitle";
import { useTranslation } from "react-i18next";
import ESaludServices from "../components/data/ESaludServicios";
import RoundIconDescription from "../components/RoundIconDescription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopMedical } from "@fortawesome/free-solid-svg-icons";
import imgSalud from "../assets/img_bg/Qoxit_eSalud.jpg";

const Esalud = () => {
  const { t } = useTranslation("common");

  return (
    <div>
      <div>
        <MainTitle
          title={t("eSalud.title")}
          description={t("eSalud.description")}
          classImg="image-service"
          classTitle="service-title"
          classParagraph="service-text"
          classMainTitle="div-main-title"
          img={imgSalud}
          icon={
            <FontAwesomeIcon
              className="image-service"
              size="6x"
              color="#4C56A5"
              icon={faLaptopMedical}
              style={{ filter: "drop-shadow(0 0 1rem)" }}
            />
          }
        />
      </div>
      <div className="div-main">
      <div className="div-pages-servicios">
        {ESaludServices.map((item, index) => {
          return (
            <RoundIconDescription
              key={index}
              class="boton-servicio"
              divClass="div-servicios-custom"
              divBoton="div-boton"
              content={item.icon}
              title={t(`eSalud.${item.title}`)}
              description={t(`eSalud.${item.desc}`)}
            />
          );
        })}
        </div>
      </div>
    </div>
  );
};
export default Esalud;
