import React from "react";
import MainTitle from "../components/MainTitle";
import { useTranslation } from "react-i18next";
import InfraestructuraServices from "../components/data/InfraestructuraServicios";
import RoundIconDescription from "../components/RoundIconDescription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import imgInfra from "../assets/img_bg/Qoxit_Infraestructura.jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Infraestructura = () => {
  const { t } = useTranslation("common");

  return (
    <div>
      <MainTitle
        title={t("infraestructura.title")}
        description={t("infraestructura.description")}
        classImg="image-service"
        classTitle="service-title"
        classParagraph="service-text"
        classMainTitle="div-main-title"
        img={imgInfra}
        icon={
          <FontAwesomeIcon
            className="image-service"
            size="6x"
            color="#4C56A5"
            icon={faCloud}
            style={{ filter: "drop-shadow(0 0 1rem)" }}
          />
        }
      />
      <div className="div-main">
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
          <div className="div-pages-servicios">
            {InfraestructuraServices.map((item, index) => {
              return (
                <RoundIconDescription
                  key={index}
                  divClass="div-servicios-custom"
                  content={item.icon}
                  title={t(`infraestructura.${item.title}`)}
                  description={t(`infraestructura.${item.desc}`)}
                />
              );
            })}
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
};
export default Infraestructura;
