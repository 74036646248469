import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Slider = (props) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1645 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1644, min: 1025 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 756 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 755, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={props.divClass}>
      <Carousel
        showDots={props.showDots}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        centerMode={props.centerMode}
        slidesToSlide={1}
        deviceType={props.deviceType}
      >
        {props.data.map((item, index) => (
          <div key={index} className={props.sliderClass}>
            <img alt={`img${index}`} className={item.class} src={item.image} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
