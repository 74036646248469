import img_PabloAlzuri_gris from "../../assets/img_team/Qoxit_PabloAlzuri_gris.png";
import img_LeonardoBiasotti_gris from "../../assets/img_team/Qoxit_LeonardoBiasotti_gris.png";
import img_IgnacioFriedmann_gris from "../../assets/img_team/Qoxit_IgnacioFriedman.png";
import img_PauloSande_gris from "../../assets/img_team/Qoxit_PauloSande_gris.png";

const EquipoData = [
  {
    id: 1,
    title: "names.name1",
    desc: "position.name1",
    icon: img_PabloAlzuri_gris,
    email: "mailto:pablo.alzuri@qoxit.com",
    linkedin: "https://www.linkedin.com/in/palzuri/",
  },
  // {
  //   id: 2,
  //   title: "names.name2",
  //   desc: "position.name2",
  //   icon: img_LeonardoBiasotti_gris,
  //   email: "mailto:leonardo.biasotti@qoxit.com",
  //   linkedin: "https://www.linkedin.com/in/lbiasotti/",
  // },
  {
    id: 3,
    title: "names.name3",
    desc: "position.name3",
    icon: img_IgnacioFriedmann_gris,
    email: "mailto:ignacio.friedmann@qoxit.com",
    linkedin: "https://www.linkedin.com/in/ignacio-friedmann/",
  },
  {
    id: 4,
    title: "names.name4",
    desc: "position.name4",
    icon: img_PauloSande_gris,
    email: "mailto:paulo.sande@qoxit.com",
    linkedin: "https://www.linkedin.com/in/paulo-sande/",
  },
];
export default EquipoData;
