import React from "react";
import MainTitle from "../components/MainTitle";
import { useTranslation } from "react-i18next";
import ImageDescription from "../components/ImageDescription.js";
import EquipoData from "../components/data/DatosEquipo";
import IconButtonLink from "../components/IconButtonLink";
import Slider from "../components/Slider";
import { SliderDataEquipo } from "../components/data/SliderDataEquipo";
import Title from "../components/Title";
import classes from "./Equipo.module.css";

import img9 from "../assets/img_team/mesa_trabajo61.jpeg";
import img10 from "../assets/img_team/mesa_trabajo.jpeg";

import ComponentDiv from "../components/ComponentDiv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imgEquipo from "../assets/img_bg//Qoxit_Equipo.jpg";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Equipo = () => {
  const { t } = useTranslation("common");
  const [hover, setHover] = React.useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const handleHoverEnter = (id) => {
    setHover({ ...hover, [id]: true });
  };

  const handleHoverLeave = (id) => {
    setHover({ ...hover, [id]: false });
  };

  return (
    <div>
      <div>
        <MainTitle
          title={t("equipo.title")}
          description={t("equipo.description")}
          classImg="image-service"
          classTitle="service-title"
          classParagraph="service-text"
          classMainTitle="div-main-title"
          img={imgEquipo}
          icon={
            <FontAwesomeIcon
              className="image-service"
              size="6x"
              color="#4C56A5"
              icon={faPeopleGroup}
              style={{ filter: "drop-shadow(0 0 1rem)" }}
            />
          }
        />
      </div>
      <div className="div-main">
        <ComponentDiv text={t("equipo.texto1")} />
        <div className="div-images">
          <ComponentDiv text={t("equipo.texto2")} img={img10} />
          <ComponentDiv text={t("equipo.texto3")} img={img9} />
        </div>
        <div className={classes.spacer} />
        <Title title={t("equipo.directores")}></Title>
        <div className={classes.spacer} />
        <div className="div-pages-equipo">
          {EquipoData.map((item, index) => {
            return (
              <AnimationOnScroll
                animateOnce={true}
                key={index}
                animateIn="animate__fadeInUp"
              >
                <div
                  style={{
                    position: "relative",
                    margin: "auto",
                    maxWidth: "250px",
                  }}
                >
                  <ImageDescription
                    image={item.icon}
                    title={t(`equipo.${item.title}`)}
                    description={t(`equipo.${item.desc}`)}
                    onMouseEnter={() => handleHoverEnter(item.id)}
                    onMouseLeave={() => handleHoverLeave(item.id)}
                    href={item.linkedin}
                    hover={hover[item.id]}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "180px",
                      right: "10px",
                    }}
                  >
                    <IconButtonLink
                      classButton={
                        hover[item.id] ? "icons icons-hover" : "icons"
                      }
                      target="_blank"
                      href={item.linkedin}
                      onMouseEnter={() => handleHoverEnter(item.id)}
                      onMouseLeave={() => handleHoverLeave(item.id)}
                      icon={<FontAwesomeIcon icon={faLinkedin} />}
                    />
                  </div>
                </div>
              </AnimationOnScroll>
            );
          })}
        </div>

        <Slider
          divClass="div-slider-equipo"
          sliderClass="slider-equipo"
          class="images"
          data={SliderDataEquipo}
          centerMode={false}
        />
      </div>
    </div>
  );
};
export default Equipo;
