import qoxitIndex from "../assets/img_bg/Qoxit_Institucional.jpg";
import Slider from "../components/Slider";
import { SliderData } from "../components/data/SliderData";
import imgMetodologia from "../assets/Qoxit_metodologia.png";
import Services from "../components/data/Servicios";
import { useTranslation } from "react-i18next";
import MainTitle from "../components/MainTitle";
import RoundIconDescription from "../components/RoundIconDescription";
import ComponentDiv from "../components/ComponentDiv";
import ensoIndex from "../assets/img_logo/ENSO_sin_fondo.png";
import Title from "../components/Title";

const Index = () => {
  const { t } = useTranslation("common");

  // const reveal = () => {
  //   var className = "div-pages-servicios reveal";
  // };

  return (
    <>
      <div className="main-page">
        <MainTitle
          title={t("index.title")}
          description={t("index.description")}
          classImg="index-image"
          classTitle="index-title"
          classParagraph="index-text"
          classMainTitle="div-main-title"
          img={qoxitIndex}
          indexImg={ensoIndex}
        />
      </div>

      <div className="div-main">
        <ComponentDiv text={t("index.sobreNosotros.description")} />

        <Title title={t("index.queHacemos.title")} />
        <div className="div-pages-servicios">
          {Services.map((item, index) => {
            return (
              <RoundIconDescription
                key={index}
                link={item.url}
                title={t(`services.${item.title}`)}
                description={t(`services.${item.desc}`)}
                content={item.icon}
              />
            );
          })}
        </div>

        <Title title={t("index.comoHacemos.title")} />
        <ComponentDiv
          img={imgMetodologia}
          text={t("index.comoHacemos.description")}
        />
        <Title title={t("index.clientes.title")} />

        <Slider
          divClass="div-slider"
          sliderClass="slider"
          data={SliderData}
          centerMode={false}
          showDots={true}
        />
      </div>
    </>
  );
};

export default Index;
