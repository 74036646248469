import classes from "./ImageDescription.module.css";

const imageClass = (hover) => {
  if (hover) {
    return `${classes["images-equipo-hover"]} ${classes["images-equipo"]}`;
  } else {
    return classes["images-equipo"];
  }
};

const ImageDescription = (props) => {
  return (
    <>
      <a href={props.href} target="_blank" rel="noreferrer">
        <div
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          alt="images"
          className={imageClass(props.hover)}
          style={{ backgroundImage: `url(${props.image})` }}
        />
      </a>
      <h4>{props.title}</h4>
      <p>{props.description}</p>
    </>
  );
};
export default ImageDescription;
