import classes from "./Title.module.css";

const Title = (props) => {
  return (
    <div className={classes["title-section"]}>
      <h1 className={classes["p-section"]}>{props.title}</h1>
    </div>
  );
};
export default Title;
