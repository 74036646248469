import React from "react";
import MainTitle from "../components/MainTitle";
import { useTranslation } from "react-i18next";
import SecurityServices from "../components/data/SeguridadServicios";
import RoundIconDescription from "../components/RoundIconDescription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import imgSeguridad from "../assets/img_bg/Qoxit_Seguridad.jpg";

const Seguridad = () => {
  const { t } = useTranslation("common");
  return (
    <div>
      <MainTitle
        title={t("seguridad.title")}
        description={t("seguridad.description")}
        classImg="image-service"
        classTitle="service-title"
        classParagraph="service-text"
        classMainTitle="div-main-title"
        img={imgSeguridad}
        icon={
          <FontAwesomeIcon
            className="image-service"
            size="6x"
            color="#4C56A5"
            icon={faShieldHalved}
            style={{ filter: "drop-shadow(0 0 0.95rem)" }}
          />
        }
      />
      <div className="div-main">
        <div className="div-pages-servicios">
          {SecurityServices.map((item, index) => {
            return (
              <RoundIconDescription
                key={index}
                class="boton-servicio"
                divClass="div-servicios-custom"
                divBoton="div-boton"
                content={item.icon}
                title={t(`seguridad.${item.title}`)}
                description={t(`seguridad.${item.desc}`)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Seguridad;
