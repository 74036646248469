
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopMedical, faCode, faChartLine, faShieldHalved, faCloud } from "@fortawesome/free-solid-svg-icons";
export const Services = [
  {
    title: "title.consultoria",
    desc: "description.consultoria",
    icon: <FontAwesomeIcon icon={faChartLine} />,
    url: "/consultoria",
  },
  {
    title: "title.seguridad",
    desc: "description.seguridad",
    icon: <FontAwesomeIcon icon={faShieldHalved} />,
    url: "/seguridad",
  },
  {
    title: "title.desarrollo",
    desc: "description.desarrollo",
    icon: <FontAwesomeIcon icon={faCode} />,
    url: "/desarrollo",
  },
  {
    title: "title.eSalud",
    desc: "description.eSalud",
    icon: <FontAwesomeIcon icon={faLaptopMedical} />,
    url: "/esalud",
  },
  {
    title: "title.infraestructura",
    desc: "description.infraestructura",
    icon: <FontAwesomeIcon icon={faCloud} />,
    url: "/infraestructura",
  },
];
export default Services;
