import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListCheck, faSitemap } from "@fortawesome/free-solid-svg-icons";

const ConsultoriaServices = [
  {
    title: "arquitecturaEmpresarial.title",
    desc: "arquitecturaEmpresarial.description",
    icon: <FontAwesomeIcon icon={faSitemap} />,
  },
  {
    title: "gestionProyectos.title",
    desc: "gestionProyectos.description",
    icon: <FontAwesomeIcon icon={faListCheck} />,
  },
];
export default ConsultoriaServices;
