import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import { Route, Routes, Navigate } from "react-router-dom";
import Index from "./pages/Index";
import Consultoria from "./pages/Consultoria";
import Seguridad from "./pages/Seguridad";
import Desarrollo from "./pages/Desarrollo";
import Infraestructura from "./pages/Infraestructura";
import Esalud from "./pages/Esalud";
import Equipo from "./pages/Equipo";
// import Contacto from "./pages/Contacto";
import "animate.css/animate.min.css";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/index" element={<Index />} />
        <Route path="/consultoria" element={<Consultoria />} />
        <Route path="/seguridad" element={<Seguridad />} />
        <Route path="/desarrollo" element={<Desarrollo />} />
        <Route path="/infraestructura" element={<Infraestructura />} />
        <Route path="/eSalud" element={<Esalud />} />
        <Route path="/equipo" element={<Equipo />} />
        {/* <Route path="/contacto" element={<Contacto />} /> */}
        <Route path="*" element={<Navigate to="/index" replace />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
