import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCode,
  faRulerCombined,
  faShuffle,
} from "@fortawesome/free-solid-svg-icons";

const DevelopmentServices = [
  {
    title: "desarrolloAMedida.title",
    desc: "desarrolloAMedida.description",
    icon: <FontAwesomeIcon icon={faRulerCombined} />,
  },
  {
    title: "desarrolloEspecializado.title",
    desc: "desarrolloEspecializado.description",
    icon: <FontAwesomeIcon icon={faFileCode} />,
  },
  {
    title: "integraciones.title",
    desc: "integraciones.description",
    icon: <FontAwesomeIcon icon={faShuffle} />,
  },
];
export default DevelopmentServices;
